/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// console.log('Hello World from Webpacker')
require("@rails/ujs").start()
// require("turbolinks").start()
// require("@rails/activestorage").start()
// require("channels")
require("jquery")
require("popper.js")
require("admin-lte")

$(function() {
  $(".close").click(function() {
    $(".alert").hide();
  });

  $(".file").on('change', function () {
    var file = $(this).prop('files')[0];
    var label = $(this).next();
    if (!($(this).children(".filename").length)) {
      $(this).parent(".form__file").append('<span class="filename"></span>');
      label.addClass('changed');
    }
    label.next().html(file.name);
  });
});


/* bills/show ガス・灯油使用量タブ */
$(function() {
  $(".biltab__area").on('click', function() {
    const tglGas = $(".tgl-gas")
    const tglKerosene = $(".tgl-kerosene")

    $(".biltab__area").removeClass('-active');
    $(this).addClass("-active")

    if($(".tab-gas").hasClass('-active')) {
      tglKerosene.removeClass('-active');
      tglGas.addClass('-active');
    } else {
      tglGas.removeClass('-active');
      tglKerosene.addClass('-active');
    }
  });
});

/* home/index トップ画面ご請求情報 ガス・灯油タブ */
$(function () {
  $(".tab-list").on("click", function () {
    const tabGas = $(".tab-gas");
    const toggleGas = $(".tgl-gas");
    const toggleKerosene = $(".tgl-kerosene");

    $(".tab-list").removeClass("-active");
    $(this).addClass("-active");

    if (tabGas.hasClass("-active")) {
      toggleKerosene.removeClass("-active")
      toggleGas.addClass("-active");
    } else {
      toggleGas.removeClass("-active");
      toggleKerosene.addClass("-active")
    }
  })
})

$(function () {
  $("#pdf-name").on("blur", function () {
    const nameCnt = $("#pdf-name").val().length;
    const alertText = $("#pdf-text-alert")
    const pdfSubmit = $("#pdf-submit")
    if (nameCnt > 50) {
      alertText.removeClass("d-none")
      alertText.addClass("d-block")
      pdfSubmit.prop('disabled', true);
    } else {
      alertText.removeClass("d-block")
      alertText.addClass("d-none")
      pdfSubmit.prop('disabled', false);
    }
  })
})



function setYAxesSuggestedMax() {
  let yAxesMaxValue = 0
  if (myBarChart.data.datasets.length == 2) {
    yAxesMaxValue = Math.max(...myBarChart.data.datasets[0].data, ...myBarChart.data.datasets[1].data)
  } else {
    yAxesMaxValue = Math.max(...myBarChart.data.datasets[0].data)
  }
  myBarChart.options.scales.yAxes[0].ticks.suggestedMax = yAxesMaxValue * 1.2
  myBarChart.update()
}

$(window).on('load', function () {
  if ($("#myBarChart").length) setYAxesSuggestedMax()
});
